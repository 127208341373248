import React, { useState } from 'react';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

// the hook
import { useTranslation } from 'react-i18next';

function HeroHome() {

  const {t, i18n} = useTranslation(['herohome']);

  const items = [
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/features-build-image.jpg')} width="100%" alt="iPhone mockup" aria-hidden="true" />,
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/kigali-ampersand.jpg')} width="100%" alt="iPhone mockup" aria-hidden="true" />,
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/hero-image-01.jpg')} width="100%" alt="iPhone mockup" aria-hidden="true" />,
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/features-collect-image.jpg')} width="100%" alt="iPhone mockup" aria-hidden="true" />,
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/kigali-map-print.jpg')} width="100%" alt="" aria-hidden="true" />,
    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/undp-calibration.jpg')} width="100%" alt="" aria-hidden="true" />,
    // <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none rounded-lg" src={require('../images/features-share-image.jpg')} width="100%" alt="iPhone mockup" aria-hidden="true" />,
  ];

  return (
    <section className="relative" style={{
      "backgroundAttachment":"scroll, scroll, scroll",
    "backgroundImage":"linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)), url(\"../images/background_header.png\")",
    "backgroundSize":"auto, auto, auto",
    "backgroundOrigin":"padding-box, padding-box, padding-box",
    "backgroundClip":"border-box, border-box, border-box",
    "backgroundColor":"rgba(67, 120, 255,0)",
    "backgroundRepeat":"no-repeat, repeat",
    "backgroundPosition":"center top,center top",
    "paddingTop":"10rem",
    "paddingBottom":"5rem"}}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-10 md:pt-40 md:pb-48">

          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-start">

            {/* Content */}
            <div className="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left">
              <h1 className="h1 lg:text-4xl mb-4 font-red-hat-display font-extrabold" data-aos="fade-down">Together, we can map <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">air quality</span></h1>
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-4" data-aos="fade-down" data-aos-delay="150">Measure the air you breathe with our portable, reliable, air quality monitor</p>
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-4" data-aos="fade-down" data-aos-delay="150">Find pollution hotspots in your city</p>
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-4" data-aos="fade-down" data-aos-delay="150">You have the power to make a difference</p>
              {/* example slides of our work */}
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-8 italic" data-aos="fade-up" data-aos-delay="200">Take a look at examples of our work <a className='underline' target="_blank" href="https://pub-2ff142b84818402d92cea35b93ba5064.r2.dev/open-seneca_lisbon-stockholm-2021-update.pdf">here</a> (pdf) and our <a className='underline' target="_blank" href="https://maps.open-seneca.org/">maps</a>!</p>

              {/* <ul className="text-lg  max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 mb-16" data-aos="fade-down" data-aos-delay="450">
                <li className="flex items-start mb-2">
                  <svg className="w-3 h-3 fill-current text-green-600 mr-2 flex-shrink-0 mt-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Measure PM2.5, temperature, and humidity with trusted sensors.</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-600 mr-2 flex-shrink-0 mt-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>All our data are geotagged and timestamped.</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-600 mr-2 flex-shrink-0 mt-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>We support communities, policy makers, and researchers.</span>
                </li>
              </ul> */}
              <div>
                  <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="#feat-home">Learn more</a> 
              </div>
            </div>

            {/* Mobile mockup */}
            <div className="md:col-span-5 lg:col-span-5 text-center md:text-right" data-aos="fade-up" data-aos-delay="450">
              <div className="relative justify-center">
                {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
                {/* iPhone mockup */}
                
                <AliceCarousel
                    autoPlay
                    autoPlayInterval={2000}
                    animationDuration={1000}
                    // animationType="fadeout"
                    autoWidth
                    responsive
                    infinite
                    // touchTracking={false}
                    // disableDotsControls
                    disableButtonsControls
                    items={items}
                />
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default HeroHome;