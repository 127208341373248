import React from 'react';
import {MdBubbleChart, MdGpsFixed} from 'react-icons/md';
import {FaTemperatureHigh, FaBluetooth, FaGrav, FaGooglePlay, FaAppStoreIos, FaGithub} from 'react-icons/fa';
import {RiSdCardMiniFill, RiComputerFill, RiUsbFill, RiOpenSourceFill} from 'react-icons/ri';
import {BiLinkAlt, BiRefresh} from 'react-icons/bi';
import {CgArrowsShrinkV} from 'react-icons/cg';
import {BsBatteryFull} from 'react-icons/bs';
import {ImYoutube2} from 'react-icons/im';

import { IconContext } from "react-icons";


function MonitorSpec() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg" data-aos="fade-right">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Measure pollution{' '}
              <br className="hidden md:block" />
              wherever you go
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
            Purchase a kit - our monitor is open-source, educational, and easy to build. Or buy one ready-to-go to immediately get started. It has a display, onboard GPS, bluetooth, and reliable sensors to measure the air around you.
            </p>
            <div className="space-x-4">
              <div className="inline text-base text-gray-700 font-bold md:text-l">Learn about it on </div> 
              <IconContext.Provider value={{ className: "inline text-gray-600 hover:text-gray-900 w-16 h-16"}}>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCP9swkvF3AYdHjAZqcAbQQA/" aria-label="YouTube">
                  <ImYoutube2 />
                </a>
              </IconContext.Provider>
            </div>
            
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8" data-aos="fade-up">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover h-28 w-28 mb-6 rounded shadow-lg sm:h-48 xl:h-56 sm:w-48 xl:w-56"
              src={require('../images/sensor-kit.jpg')} 
              alt=""
            />
            <img
              className="object-cover w-16 h-16 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src={require('../images/features-build-image.jpg')} 
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-36 h-36 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src={require('../images/sensor-bike.jpg')} 
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  
    <div className="px-4 py-20 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid grid-cols-3 gap-5 row-gap-5 sm:grid-cols-3 lg:grid-cols-6" data-aos="fade-up">
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <MdBubbleChart />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            PM2.5
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <FaTemperatureHigh />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            T / RH
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <MdGpsFixed />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            GPS
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <RiSdCardMiniFill />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            MicroSD
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <FaBluetooth />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Bluetooth / app
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <RiComputerFill />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            OLED display
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <FaGrav />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Accelerometer
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <BiLinkAlt />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Universal mounting
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <CgArrowsShrinkV />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Small size<sup>1</sup>
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <BiRefresh />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            1s record time
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <BsBatteryFull />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Long battery life<sup>2</sup>
          </h6>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <RiOpenSourceFill />
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            open-source
          </h6>
        </div>
      </div>
      <p className="text-xs text-gray-700"><sup>1</sup> 82&nbsp;mm x 57&nbsp;mm x 34&nbsp;mm</p>
      <p className="text-xs text-gray-700"><sup>2</sup> A 1000&nbsp;mAh battery is included in every kit. One charge should last a week of average use (30-45 mins a day). 5 hours of continuous use. </p>
    </div>
      
    {/* Items */}
    <div className="grid gap-32 py-12">

    {/* 1st item */}
    <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
      {/* Image */}
      <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0" data-aos="fade-up">
        <div className="inline-flex relative justify-center items-center mx-16">
            {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
            <img className="absolute" src={require('../images/os-app.png')} width="290" height="624" style={{ maxWidth: '84.33%' }} alt="Features illustration" />
            {/* iPhone mockup */}
            <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none" src={require('../images/iphone-mockup.png')} width="344" height="674" alt="iPhone mockup" aria-hidden="true" />
        </div>
      </div>
      {/* Content */}
      <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pr-6" data-aos="fade-right">
        <div className="md:pr-4 lg:pr-12 xl:pr-16">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
          Connect to our app
        </h2>

        <p className="text-base text-gray-700 md:text-lg mb-4">
        See your data live. See historical tracks. Report pollution events on our community driven map.
        </p>
        <div className="space-x-4">
          <div className="inline text-base text-gray-700 font-bold md:text-l"> Download on </div> 
          <IconContext.Provider value={{ className: "inline text-gray-600 hover:text-gray-900 w-8 h-8"}}>
            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.opensenecasensorapp" aria-label="Google Play">
              <FaGooglePlay />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "inline text-gray-600 hover:text-gray-900 w-8 h-8"}}>
            <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/gb/app/open-seneca/id1557645819" aria-label="Apple app store">
              <FaAppStoreIos />
            </a>
          </IconContext.Provider>
        </div>
            
        </div>
      </div>
    </div>

    {/* 2nd item */}
    <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
      {/* Image */}
      <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 p-16" data-aos="fade-up">
        <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/github-screenshot.jpg')} width="100%" alt="Features 02" />
      </div>
      {/* Content */}
      <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-6" data-aos="fade-left">
        <div className="md:pl-4 lg:pl-12 xl:pl-16">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
          Write your own code
        </h2>
          <p className="text-base text-gray-700 md:text-lg mb-4">Our board comes with pre-installed firmware ready to go. If you would like to add your own features you are welcome to contribute to our open-source code.</p>

          <div className="space-x-4">
          <div className="inline text-base text-gray-700 font-bold md:text-l">Contribute on </div> 
          <IconContext.Provider value={{ className: "inline text-gray-600 hover:text-gray-900 w-8 h-8"}}>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/open-seneca/integrated-aq-sensor" aria-label="Github sensor">
              <FaGithub />
            </a>
          </IconContext.Provider>
        </div>
        </div>
      </div>
    </div>

    </div>

 
      </div>
    </section>
  );
}

export default MonitorSpec;