import React from 'react';

function PolicyFeatures() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">


          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 lg:text-4xl mb-4 text-gray-600 font-red-hat-display font-extrabold">For <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">evidence-based policy</span></h1>
            <p className="text-lg text-gray-600">
              We enable solutions for policy makers who want to make a difference.
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-32">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/features-share-image.jpg')} width="100%"  alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Monitor your city</h3>
                  <p className="text-xl text-gray-400 mb-4">Complement your current stationary monitoring network with a network of our reliable low-cost, mobile sensors to measure air quality with a very high spatial detail.</p>
                  {/* <div className="font-architects-daughter text-xl text-blue-600 mb-2">With our network of mobile sensors you will be able to:</div> */}
                  <ul className="text-lg text-gray-400 -mb-2">
                    <li className="flex items-start mb-2">
                      <svg className="w-3 h-3 fill-current text-blue-600 mr-2 flex-shrink-0 mt-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="2" />
                      </svg>
                      <span>Understand the spatial and temporal distribution of air pollution in your city with our street-by-street level maps</span>
                    </li>
                    <li className="flex items-start mb-2">
                      <svg className="w-3 h-3 fill-current text-blue-600 mr-2 flex-shrink-0 mt-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="2" />
                      </svg>
                      <span>Identify hotspots of pollution</span>
                    </li>
                    <li className="flex items-start mb-2">
                      <svg className="w-3 h-3 fill-current text-blue-600 mr-2 flex-shrink-0 mt-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="2" />
                      </svg>
                      <span>Measure the impact of intervention activities aimed at improving air quality and inform their replication and scale-up</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="w-3 h-3 fill-current text-blue-600 mr-2 flex-shrink-0 mt-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="2" />
                      </svg>
                      <span>Obtain valuable and reliable data for evidence-based policy making</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/hero-image-01.jpg')} width="100%" alt="Features 02" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pr-6" data-aos="fade-left">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3">Involve your citizens</h3>
                  <div className="flex mb-8">
                {/* <img className="rounded-full self-start flex-shrink-0 shadow-lg mr-4" src={require('../images/about-seneca.jpg')} width="40" height="40" alt="Logo" /> */}
                <div>
                  <blockquote className="text-gray-600 italic">
                    “ There is no power for change greater than a community discovering what it cares about. ”
                  </blockquote>
                  <div className="text-sm font-medium text-gray-600 mt-2">
                    <cite className="text-gray-900 not-italic">Margaret J Wheatley</cite>
                  </div>
                </div>
            </div>
                  <p className="text-xl text-gray-400 mb-4">When citizens carry our portable sensors, they can see their personal exposure to air pollution and become aware of the problem. Aware citizens can take action at the individual level to reduce emissions and drive behaviour change in their communities.</p>
                  <p className="text-xl text-gray-400 mb-4">Let citizens become active stakeholders and help you identify feasible and scalable solutions to improve air quality in your city.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default PolicyFeatures;