import React, { useState } from 'react';

const resources = [{
    'title' : 'Our World in Data - Air Pollution',
    'description' : 'Great resource for infographics on air pollution and the negative health effects of air pollution.',
    'url' : 'https://ourworldindata.org/air-pollution/',
    'categories' : ['','1'],
    'img' : ''  
},
{
    'title' : 'Clean Air Fund - Insights',
    'description' : 'News, lessons and perspectives from the global clean air field.',
    'url' : 'https://www.cleanairfund.org/insights/',
    'categories' : ['','1'],
    'img' : ''  
},
{
    'title' : 'World Health Organisation',
    'description' : 'Great source of fact sheets, WHO reports on global air pollution and WHO interventions.',
    'url' : 'https://www.who.int/health-topics/air-pollution/',
    'categories' : ['','1'],
    'img' : ''  
},
{
    'title' : 'IQAir - Air quality in the world',
    'description' : 'Amazing summary of PM pollution + pollution rankings. Ideal to look up air pollution around the globe with their interactive, satellite-data-augmented world map.',
    'url' : 'https://www.iqair.com/world-air-quality/',
    'categories' : ['','1','2'],
    'img' : ''  
},
{
    'title' : 'NASA Climate Kids',
    'description' : 'Children-friendly explanations and videos of air pollution, aerosols, the ozone layer and climate change.',
    'url' : 'https://climatekids.nasa.gov/air-pollution/',
    'categories' : ['','1','4'],
'img' : ''  
},
{
    'title' : 'National Institute of Environmental Health Sciences (NIH) Kids Environment',
    'description' : 'Learning hub for kids with information on air and water pollution as well as introductions to adverse health effects and analytic techniques',
    'url' : 'https://kids.niehs.nih.gov/topics/pollution/index.htm',
    'categories' : ['','1','3','4'],
    'img' : ''  	
},
{
    'title' : 'Britannica Kids',
    'description' : 'Student-level summary learning hub on air pollution, global warming and health problems.',
    'url' : 'https://kids.britannica.com/students/article/air-pollution/598797',
    'categories' : ['','1','3','4'],
    'img' : '' 
},
{
    'title' : 'National Institute of Environmental Health Sciences (NIH) Education.',
    'description' : 'Adult-level summary of air quality, individual pollutants and their respective sources as well as health effects.',
    'url' : 'https://www.niehs.nih.gov/health/topics/agents/air-pollution/index.cfm',
    'categories' : ['','1'],
    'img' : '' 
},
{
    'title' : 'World Health Organization Health Topics: Air Pollution',
    'description' : 'Overview of indoor and outdoor air pollution as well as detailed information of globally accepted WHO guidelines of air pollution.',
    'url' : 'https://www.who.int/health-topics/air-pollution#tab=tab_1',
    'categories' : ['','1'],
    'img' : '' 
}];

const categories = {
    
    '' : {
        'name' : 'All',
        'color' : 'gray',
        'img' : "M11 7H1a1 1 0 000 2h10v3l5-4-5-4v3zM8 3H1a1 1 0 010-2h7a1 1 0 010 2zM8 15H1a1 1 0 010-2h7a1 1 0 010 2z"
    },
    '1' : {
        'name' : 'Learning',
        'color' : 'teal',
        'img' : "M13.172 5.178l-2.39-2.39 2.39-2.39C12.674.1 12.076 0 11.479 0a4.462 4.462 0 00-4.481 4.48c0 .499.1.897.199 1.395l-6.572 4.88c-.797.597-.797 1.692-.2 2.389l2.291 2.39c.697.697 1.792.597 2.39-.2l4.88-6.571c.497.1.995.199 1.493.199 2.49 0 4.48-1.992 4.48-4.481 0-.598-.099-1.195-.298-1.693l-2.49 2.39z",
    },
    '2' : {
        'name' : 'Maps',
        'color' : 'red',
        'img' : "M5 16H4a4 4 0 01-4-4v-1h2v1a2 2 0 002 2h1v2zM13 10h-1.586l-1.207-1.207L14.37 4.63a2.121 2.121 0 00-3-3L7.207 5.793 5.99 4.576 5.98 3.02 3.038.079 0 3.117 3 6h1.586l1.207 1.207L4 9l3 3 1.793-1.793L10 11.414V13l3.01 3.01 2.98-2.979L13 10z"  

    },
    '3' : {
        'name' : 'Videos',
        'color' : 'blue',
        'img' : "M12.071 0L15 2.929v4.142L12.071 10H12V7.101L7.9 3H5v-.071L7.929 0h4.142zm-5 5L10 7.929v4.142L7.071 15H2.929L0 12.071V7.929L2.929 5h4.142z"  

    },
    '4' : {
        'name' : 'For kids',
        'color' : 'purple',
        'img' : "M1 0h8a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm14.124 4.085v-.001a1 1 0 01.868 1.116l-1.243 9.932a1 1 0 01-1.117.868l-7.938-1 .248-1.988 6.946.871.995-7.938-2.007-.251.248-1.984 3 .375z"  

    }
}

function ResourceList() {

  const [category, setCategory] = useState('');

  return (
<section className="relative border-t border-transparent dark:border-gray-800">
    {/* Background gradient (dark version only) */}
    <div className="absolute inset-0 opacity-25 bg-gradient-to-b from-gray-800 to-gray-900 pointer-events-none hidden dark:block" aria-hidden="true"></div>
    {/* End background gradient (dark version only) */}
    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

            <div data-aos="fade-up" data-aos-delay="450">

                {/* Category buttons */}
                <div className="mb-10">
                    <div className="flex flex-wrap justify-center -m-1">
                        {Object.entries(categories).sort((a, b) => a[0].localeCompare(b[0])).map(( element, i) => 
                            <button
                            className={`font-medium px-4 py-2 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 shadow transition duration-150 ease-in-out rounded-full inline-flex items-center justify-center m-1 ${category === element[0] && 'bg-' + element[1]['color'] + '-500 hover:bg-' + element[1]['color'] + '-500 dark:bg-' + element[1]['color'] + '-600 dark:hover:bg-' + element[1]['color'] + '-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                            onClick={() => setCategory(element[0])}
                            >
                            <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-current ${category === element[0] ? 'text-' + element[1]['color'] + '-200 dark:text-' + element[1]['color'] + '-400' : 'text-' + element[1]['color'] + '-500'}`} d={element[1]['img']} />
                            </svg>
                                <span className={`transition duration-150 ease-in-out ${category === element[0] ? 'text-white dark:text-' + element[1]['color'] + '-400' : 'text-gray-600 dark:text-gray-300'}`}>{element[1]['name']}</span>
                            </button>
                        )}
                    </div>
                </div>

                {/* Articles */}
                <div className="-mb-2">
                {Object.entries(resources).map(( element, i) => 
                    <article className={`mb-2 ${!element[1]['categories'].includes(category) && 'hidden'}`}>
                        <div className="flex pr-6 py-5 bg-white dark:bg-gray-800 divide-x divide-gray-200 dark:divide-gray-700 shadow-2xl rounded-lg">
                            {/* Category icon */}
                            <div className="flex items-center px-4 sm:px-8">
                                {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="fill-current text-teal-100 dark:hidden" cx="16" cy="16" r="16" /> 
                                    <path className="fill-current text-teal-500" d={element[1]['img']} />
                                </svg> */}
                            </div>
                            {/* Content */}
                            <div className="pl-6">
                                <header>
                                    <h3 className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                                        <a href={element[1]['url']}>{element[1]['title']}</a>
                                    </h3>
                                </header>
                                <p className="text-gray-600 dark:text-gray-400">
                                    {element[1]['description']}
                                </p>
                                <a className="inline-flex items-center font-medium text-gray-500 hover:underline mt-4" href={element[1]['url']}>
                                    <span>{element[1]['url']}</span>
                                    <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                                    </svg>
                                </a>
                                <footer className="text-sm flex items-center mt-4">
                                    <div className="flex flex-shrink-0 mr-3">
                                        {element[1]['categories'].map((e,i) => {
                                            if (e !== '') {
                                                return <svg className="relative w-4 h-4 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <circle className={"fill-current text-" + categories[e]['color'] + "-100 dark:hidden"} cx="8" cy="8" r="8" /> 
                                                <path className={"fill-current text-" + categories[e]['color'] + "-500"} d={categories[e]['img']} />
                                                </svg>
                                            }
                                        })}
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </article>

                )}


                </div>

                {/* Pagination */}
                {/* <nav className="flex justify-center sm:justify-between mt-12">
                    <span className="hidden sm:inline-flex items-center font-medium text-gray-400 dark:text-gray-500" href="#0">
                        <svg className="w-3 h-3 flex-shrink-0 mt-px mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-current" d="M5.091 11L0 5.909 5.091.818l.875.875-3.602 3.591h9.329v1.25H2.364l3.602 3.602z" />
                        </svg>
                        <span>Previous</span>
                    </span>
                    <ul className="flex">
                        <li className="mx-1">
                            <a className="inline-flex text-teal-500 px-1 border-b border-teal-500 transition duration-150 ease-in-out" href="#0">1</a>
                        </li>
                        <li className="mx-1">
                            <a className="inline-flex text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-1 transition duration-150 ease-in-out" href="#0">2</a>
                        </li>
                        <li className="mx-1">
                            <a className="inline-flex text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-1 transition duration-150 ease-in-out" href="#0">3</a>
                        </li>
                        <li className="mx-1">
                            <span className="inline-flex text-gray-600 dark:text-gray-400" href="#0">…</span>
                        </li>
                        <li className="mx-1">
                            <a className="inline-flex text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-1 transition duration-150 ease-in-out" href="#0">7</a>
                        </li>
                        <li className="mx-1">
                            <a className="inline-flex text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-1 transition duration-150 ease-in-out" href="#0">8</a>
                        </li>
                        <li className="mx-1">
                            <a className="inline-flex text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-1 transition duration-150 ease-in-out" href="#0">9</a>
                        </li>
                    </ul>
                    <a className="hidden sm:inline-flex items-center font-medium text-teal-500 hover:underline" href="#0">
                        <span>Next</span>
                        <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                        </svg>
                    </a>
                </nav> */}

            </div>

        </div>
    </div>
</section>
  );
}

export default ResourceList;