import React, { useState, useEffect } from 'react';

import Header from '../partials/Header';

import Footer from '../partials/Footer';

import ReCAPTCHA from "react-google-recaptcha";

import emailjs from 'emailjs-com';
 
let captcha;

function Contact() {
  const[captchaValue, setCaptchaValue] = useState();
  const[captchaError, setCaptchaError] = useState();
  const [loading, setLoading] = useState(false);
  const [
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
  ] = useState(false);


  function onFormSubmit(e) {
    //Prevents the page from refreshing after a form submission.
    console.log(e);
    e.preventDefault();
    if(captchaValue == undefined || null){
      setCaptchaError(true);
      console.log('captcha not set');
    } else {
      setLoading(true);
      emailjs.sendForm('service_sq307ul', 'template_42uysy5', e.target, 'user_FjM4AlUXNEwEXoX6UYO0a')
      .then((result) => {
        document.getElementById('form').reset();
        captcha.reset();
        setCaptchaValue(null);
        console.log('Email Status: ', result);
        setIsSuccessfullySubmitted(true);
        setLoading(false);
      }, (error) => {
          console.log('Email Status: ', error);
      });
      
    };
  };

  function captchaSubmit(value) {
    setCaptchaError(false);
    setCaptchaValue(value);
    // console.log(value);
  };
  
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/* Contact section */}
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4" data-aos="fade-up">How can we help you?</h1>
                <p className="text-lg text-orange-400 mb-4" data-aos="fade-up" data-aos-delay="200">We have unfortunately run out of funding, but we are nonetheless happy to hear from you :)</p>
              </div>

              {/* Contact form */}
              <form className="max-w-xl mx-auto"  onSubmit={onFormSubmit} id='form'>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="first-name">First Name</label>
                    <input name="first-name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your first name" required />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="last-name">Last Name</label>
                    <input name="last-name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your last name" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email</label>
                    <input name="email" type="email" className="form-input w-full text-gray-800" placeholder="Enter your email address" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="subject">Subject</label>
                    <input name="subject" type="text" className="form-input w-full text-gray-800" placeholder="How can we help you?" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="country">Location</label>
                    <input name="country" type="text" className="form-input w-full text-gray-800" placeholder="Area, Country" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="message">Message</label>
                    <textarea name="message" rows="4" className="form-textarea w-full text-gray-800" placeholder="Write your message" required></textarea>
                  </div>
                </div>
                <ReCAPTCHA
                  ref={el => { captcha = el; }}
                  sitekey="6LcA79MaAAAAADSiTDRMA-8R1dMZEp7SSFvWYsfd"
                  onChange={captchaSubmit}
                />
                <div className="flex flex-wrap -mx-3 mt-4">
                  <div className="w-full px-3">
                    <button type="submit" className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">
                      {loading ?
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      :
                      "Send"
                    }
                    </button>
                  </div>

                  
                </div>
                {/* Success message */}
                {isSuccessfullySubmitted && (
                  <p className="mt-2 text-green-600 text-sm">Thank you! We'll get back to you as soon as we can.</p>
                )}

                {captchaError && (
                  <p className="mt-2 text-red-600 text-sm">Please verify you're not a robot.</p>
                )}

                {/* <div className="text-sm text-gray-600 mt-4">
                  By clicking "send" you consent to allow open-seneca to store and process the personal information submitted above and agree to our <a className="underline" href="#0">terms and conditions</a> as well as our <a className="underline" href="#0">Privacy Policy</a>.
                </div> */}
              </form>

            </div>
          </div>
        </section>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Contact;