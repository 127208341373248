import React from 'react';

function Team() {
    return (
        <section
            className="bg-gray-100 mb-12">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2">The humans behind the initiative</h2>
                    </div>

                    {/* Team members */}
                    <div
                        className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3"
                        data-aos-id-team>

                        {/* 1st member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-01.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 01"/>
                                <h4 className="text-xl font-bold mb-1">Lorena Gordillo Dagallier</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">CEO & Co-founder</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="#0">Twitter</a>
                                    · */}
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://github.com/lorenamgd/">GitHub</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/lorena-gordillo-dagallier-40b736125/">LinkedIn</a>
                                </div>
                            </div>
                        </div>

                        {/* 2nd member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-delay="150"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-02.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 02"/>
                                <h4 className="text-xl font-bold mb-1">Raphaël Jacquat</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">CTO & Co-founder</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://twitter.com/BJacquat/">Twitter</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://github.com/rj380cam/">GitHub</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/rapha%C3%ABl-jacquat-134ba312b/">LinkedIn</a>
                                </div>
                            </div>
                        </div>

                        {/* 3rd member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-delay="300"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-03.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 03"/>
                                <h4 className="text-xl font-bold mb-1">Sebastian Horstmann</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">Community Manager</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://twitter.com/realBastiHo/">Twitter</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://github.com/sh969/">GitHub</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/s-horstmann/">LinkedIn</a>
                                </div>
                            </div>
                        </div>

                        {/* 4th member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-delay="450"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-04.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 04"/>
                                <h4 className="text-xl font-bold mb-1">Peter Pihlmann Pedersen</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">Lead Front-end Engineer</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://twitter.com/358ppm">Twitter</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://github.com/ppp-one">GitHub</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/peterpihlmannpedersen/">LinkedIn</a>
                                </div>
                            </div>
                        </div>

                        {/* 5th member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-delay="600"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-05.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 05"/>
                                <h4 className="text-xl font-bold mb-1">Christoph Franck</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">Head of Design</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    {/* <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="#0">Twitter</a>
                                    ·
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="#0">GitHub</a>
                                    · */}
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/christoph-franck-5a3076144/">LinkedIn</a>
                                </div>
                            </div>
                        </div>

                        {/* 6th member */}
                        <div
                            className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
                            data-aos="zoom-y-out"
                            data-aos-delay="600"
                            data-aos-anchor="[data-aos-id-team]">
                            <div className="flex flex-col items-center">
                                <img
                                    className="rounded-full mb-4"
                                    src={require('../images/team-member-06.png')}
                                    width="180"
                                    height="180"
                                    alt="Team member 05"/>
                                <h4 className="text-xl font-bold mb-1">Charles Nicklas Christensen</h4>
                                {/* <div className="text-blue-600 font-medium mb-2">Head of Design</div> */}
                                {/* <p className="text-gray-600 text-center mb-3">Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum sint occaecat cupidatat.</p> */}
                                <div className="text-sm text-gray-600 font-medium">
                                    <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://twitter.com/charlesnchr/">Twitter</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://github.com/charlesnchr/">GitHub</a> · <a target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/charlesnchr/">LinkedIn</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-12 md:py-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2">with amazing support from</h2>
                        <p className="text-lg text-gray-600 mt-4">Please go follow them!</p>
                    </div>

                                    {/* Logos */}
                    <div className="flex gap-2 h-48">
                    <div className="flex-1">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/c/PhilS94/"><img className="max-h-full self-center mx-auto" src={require('../images/PhilsLabLogo.png')} alt="Phil's Lab"/></a>
                        
                    </div>

                    <div className="flex-1">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kalakiev.com/"><img className="max-h-full self-center mx-auto rounded-full" alt="Lionel Garcia" src={require('../images/kalakiev.jpg')} /></a>
                    </div>
                    </div>

                </div>

                <div className="py-12 md:py-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2">Our partners</h2>
                    </div>

                                    {/* Logos */}
                    <div className="flex gap-2 h-48">
                    <div className="flex-1">
                        <a target="_blank" rel="noopener noreferrer" href="https://centreforglobalequality.org/"><img className="max-h-full self-center mx-auto" src={require('../images/cge-logo.png')} /></a>
                    </div>

                    <div className="flex-1">
                    <a target="_blank" rel="noopener noreferrer" href="https://cdt.sensors.cam.ac.uk/"><img className="max-h-full self-center mx-auto rounded-full" src={require('../images/sensorcdt-logo.jpg')} /></a>
                    </div>
                    </div>

                </div>



            </div>
        </section>
    );
}

export default Team;