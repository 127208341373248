import React from 'react';
import { Link } from 'react-router-dom';

import {IoAccessibilityOutline} from 'react-icons/io5';
import {GiPartyPopper, GiMaterialsScience} from 'react-icons/gi';
import {RiSignalTowerLine} from 'react-icons/ri';

import { IconContext } from "react-icons";


function FeaturesBlocks() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      {/* <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Explore the solutions</h2>
            <p className="text-xl text-gray-600">Find the one which best suits you.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none">

            {/* 0th item */}
            <Link to="/air-quality-monitor" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCP9swkvF3AYdHjAZqcAbQQA/"  className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <div className="flex justify-center items-center text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow transition duration-150 ease-in-out m-4">
              <IconContext.Provider value={{ className: "w-8 h-8 fill-current m-4"}}>
                <IoAccessibilityOutline />
              </IconContext.Provider>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">For you</h4>
              {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Link>

            {/* 1st item */}
            <Link to="/community-engagement" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCP9swkvF3AYdHjAZqcAbQQA/"  className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <div className="flex justify-center items-center text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow transition duration-150 ease-in-out m-4">
              <IconContext.Provider value={{ className: "w-8 h-8 fill-current m-4"}}>
                <GiPartyPopper />
              </IconContext.Provider>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">For communities</h4>
              {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Link>

            {/* 2nd item */}
            <Link to="/policy-making" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCP9swkvF3AYdHjAZqcAbQQA/"  className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <div className="flex justify-center items-center text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow transition duration-150 ease-in-out m-4">
              <IconContext.Provider value={{ className: "w-8 h-8 fill-current m-4"}}>
                <RiSignalTowerLine />
              </IconContext.Provider>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">For policy</h4>
              {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Link>

            {/* 3rd item */}
            <Link to="/research" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCP9swkvF3AYdHjAZqcAbQQA/"  className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <div className="flex justify-center items-center text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow transition duration-150 ease-in-out m-4">
              <IconContext.Provider value={{ className: "w-8 h-8 fill-current m-4"}}>
                <GiMaterialsScience />
              </IconContext.Provider>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">For research</h4>
              {/* <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Link>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
