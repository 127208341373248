import React from 'react';

import Header from '../partials/Header';

import MonitorIntro from '../partials/MonitorIntro';
import MonitorSpec from '../partials/MonitorSpec';

import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

function AirQualityMonitor() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <MonitorIntro />
        <MonitorSpec />
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default AirQualityMonitor;