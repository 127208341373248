import React from 'react';

function AboutStory() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">Our story</h3>
            <p className="text-lg text-gray-600 mb-8">
              Started as a project in Summer 2018, a group of budding students from the University of Cambridge came together to build a low-cost, community enabling, initiative to tackle air pollution in a global context.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              Given the cycling nature of Cambridge and environmentally minded students, our first project, the ‘Cambike Sensor’, was developed as an open-hardware device which monitored air quality and road surface quality. The project concluded with a group of volunteers who cycled around the city for a few weeks, forming our first pilot. The results and response of the initial project were very positive.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              Since then, the project has expanded under the name ‘open-seneca’, taking after the first known account of air quality, and maintaining our interest in open-source. 
            </p>
          </div>

          <div className="sm:flex">
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/about-02.jpg')} width="435" height="326" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16">
              <div className="flex mb-8">
                <img className="rounded-full self-start flex-shrink-0 shadow-lg mr-4" src={require('../images/about-seneca.jpg')} width="40" height="40" alt="Logo" />
                <div>
                  <blockquote className="text-gray-600 italic">
                    “ Well, no sooner had I left behind the oppressive atmosphere of the city and that reek of smoking cookers ... I noticed the change in my condition at once. You can imagine how much stronger I felt after reaching my vineyards! ... So by now I am quite my old self again. That feeling of listlessness, being bodily ill at ease and mentally inefficient, didn't last. I'm beginning to get down to some whole-hearted work. ”
                  </blockquote>
                  <div className="text-sm font-medium text-gray-600 mt-2">
                    <cite className="text-gray-900 not-italic">Seneca in 61 A.D.</cite>
                  </div>
                </div>
            </div>
            <p className="text-lg text-gray-600 mb-8">
              Over the years, the team has gained in-depth experience in the design of portable, low-cost, air quality monitors, capacity building in partner cities, the education and engagement of communities, and the calibration, analysis, and visualisation of the data collected with the low-cost sensors to aid with decision making.               
            </p>

            </div>
          </div>

          {/* <div className="max-w-3xl mx-auto">
            <p className="text-lg text-gray-600">
              Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat auctor urna nunc id cursus metus aliquam eleifend, arcu dictum varius duis at consectetur lorem donec massa sapien, sed risus ultricies tristique nulla aliquet. Morbi tristique senectus et netus et, nibh nisl condimentum id venenatis a condimentum vitae sapien.
            </p>
          </div> */}

        </div>
      </div>
    </section>
  );
}

export default AboutStory;