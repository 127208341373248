import React from 'react';

import {useTranslation} from 'react-i18next';

function FeaturesHome() {

  const { t, i18n } = useTranslation();

    return (
        <section className="relative">

            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div
                className="absolute inset-0 bg-gray-100 pointer-events-none"
                aria-hidden="true"></div>
            <div
                id='feat-home'
                className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-12 md:pt-20">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h1 className="h2 mb-4">How open-seneca works</h1>
                        {/* <p className="text-xl text-gray-600">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.</p> */}
                    </div>

                    {/* Items */}
                    <div
                        className="max-w-sm mx-auto grid gap-12 md:gap-x-6 lg:gap-x-12 md:grid-cols-3 items-start md:max-w-none"
                        data-aos-feat-home>

                        {/* 1st item */}
                        <div
                            className="relative flex flex-col items-center"
                            data-aos="fade-down"
                            data-aos-anchor="[data-aos-feat-home]">
                            {/* Mobile mockup */}
                            <div className="inline-flex relative justify-center items-center">
                                <img
                                    className="relative max-w-full mx-auto h-auto pointer-events-none"
                                    src={require('../images/features-build.png')}
                                    width="300"
                                    alt="iPhone mockup"
                                    aria-hidden="true"/>
                            </div>
                            {/* Description */}
                            <div className="w-12 h-12 flex justify-center items-center h3 mt-8 lg:mb-8">Build</div>
                            <span className="text-center px-8 text-gray-600">You can build your own low-cost portable air quality monitor, or buy one ready-to-go.</span>
                        </div>

                        {/* 2nd item */}
                        <div
                            className="relative flex flex-col items-center"
                            data-aos="fade-down"
                            data-aos-anchor="[data-aos-feat-home]"
                            data-aos-delay="300">
                            {/* Mobile mockup */}
                            <div className="inline-flex relative justify-center items-center">
                                <img
                                    className="relative max-w-full mx-auto h-auto pointer-events-none"
                                    src={require('../images/features-collect.png')}
                                    width="300"
                                    alt="iPhone mockup"
                                    aria-hidden="true"/>
                            </div>
                            {/* Description */}
                            <div className="w-12 h-12 flex justify-center items-center h3 mt-8 lg:mb-8">Collect</div>
                            <span className="text-center px-8 text-gray-600">Measure air quality in your area as you make your daily journeys and see your personal exposure.</span>
                        </div>

                        {/* 3rd item */}
                        <div
                            className="relative flex flex-col items-center"
                            data-aos="fade-down"
                            data-aos-anchor="[data-aos-feat-home]"
                            data-aos-delay="600">
                            {/* Mobile mockup */}
                            <div className="inline-flex relative justify-center items-center">
                                <img
                                    className="relative max-w-full mx-auto h-auto pointer-events-none"
                                    src={require('../images/features-share.png')}
                                    width="300"
                                    alt="iPhone mockup"
                                    aria-hidden="true"/>
                            </div>
                            {/* Description */}
                            <div className="w-12 h-12 flex justify-center items-center h3 mt-8 lg:mb-8">Share</div>
                            <span className="text-center px-8 text-gray-600">Share your measurements with your community, policy makers, and researchers to make a difference.</span>
                        </div>

                    </div>
                </div >

                <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-12 md:pt-20">

                        {/* Items */}
                        <div className="grid gap-20 py-32">

                            {/* Item */}
                            <div
                                className="md:grid md:grid-cols-12 md:gap-6 items-center"
                                data-aos-feat-build>

                                {/* Image */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                                    data-aos="fade-right"
                                    data-aos-delay="400"
                                    data-aos-anchor="[data-aos-feat-build]">
                                    <img
                                        className="mx-auto md:max-w-none rounded-lg"
                                        src={require('../images/features-build-image.jpg')}
                                        width="400"
                                        alt="Features 02"/>
                                </div>

                                {/* Content */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                        <div
                                            className="my-6"
                                            data-aos="fade-left"
                                            data-aos-delay="600"
                                            data-aos-anchor="[data-aos-feat-build]">
                                            <h4 className="h4 mb-2">
                                                <span className="text-blue-500">. </span>
                                                For the curious</h4>
                                            <p className="text-lg text-gray-400">Purchase a kit - our monitor is open-source, educational, and easy to build. Or buy one ready-to-go to immediately get started. It has a display, onboard GPS, bluetooth, and reliable sensors to measure the air around you.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Item */}
                            <div
                                className="md:grid md:grid-cols-12 md:gap-6 items-center"
                                data-aos-feat-collect>
                                {/* Content */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                        <div
                                            className="my-6"
                                            data-aos="fade-right"
                                            data-aos-delay="600"
                                            data-aos-anchor="[data-aos-feat-collect]">
                                            <h4 className="h4 mb-2">
                                                <span className="text-blue-500">. </span>
                                                For the adventurous</h4>
                                            <p className="text-lg text-gray-400">Take a sensor everywhere you go and see the routes you create. The flexible mounting system permits you mount it on your bike, (electric!) car, or on yourself.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Image */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                                    data-aos="fade-left"
                                    data-aos-delay="400"
                                    data-aos-anchor="[data-aos-feat-collect]">
                                    <img
                                        className="mx-auto md:max-w-none rounded-lg"
                                        src={require('../images/features-collect-image.jpg')}
                                        width="400"
                                        alt="Features 02"/>
                                </div>
                            </div>

                            {/* Item */}
                            <div
                                className="md:grid md:grid-cols-12 md:gap-6 items-center"
                                data-aos-feat-share>

                                {/* Image */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                                    data-aos="fade-right"
                                    data-aos-delay="400"
                                    data-aos-anchor="[data-aos-feat-share]">
                                    <img
                                        className="mx-auto md:max-w-none rounded-lg"
                                        src={require('../images/features-share-image.jpg')}
                                        width="400"
                                        alt="Features 02"/>
                                </div>

                                {/* Content */}
                                <div
                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                        <div
                                            className="my-6"
                                            data-aos="fade-left"
                                            data-aos-delay="600"
                                            data-aos-anchor="[data-aos-feat-share]">
                                            <h4 className="h4 mb-2">
                                                <span className="text-blue-500">. </span>
                                                For the positive impact</h4>
                                            <p className="text-lg text-gray-400">Your anonymised measurements can help build a detailed air quality map of your city. Find hotspots, engage with proposing solutions, enable evidence-based policy and promote research into air quality.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div >

            </div >
        </section >
    );
}

export default FeaturesHome;
