import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition.js';
import Dropdown from '../utils/Dropdown';

function Header() {


  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img src="./logo.svg" width="175em" alt="open-seneca logo"></img>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex">

            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {/* 0th level: hover */}
              <a href='https://maps.open-seneca.org/' className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                Maps
              </a>
              {/* 1st level: hover */}
              <Dropdown title="Technology">
                {/* 2nd level: hover */}
                <li>
                  <Link to="/air-quality-monitor" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Air quality monitor</Link>
                </li>
                {/* <li>
                  <Link to="/data-visualisation" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Data visualisation</Link>
                </li> */}
              </Dropdown>
              <Dropdown title="Learn">
                {/* 2nd level: hover */}
                <li>
                  <Link to="/about" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">About us</Link>
                </li>
                {/* <li>
                  <Link to="/projects" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Our projects</Link>
                </li>
                <li>
                  <Link to="/resources" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Resources</Link>
                </li> */}
              </Dropdown>

              <Dropdown title='Solutions'>
                {/* 2nd level: hover */}
                <li>
                  <Link to="/community-engagement" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Community Engagement</Link>
                </li>
                <li>
                  <Link to="/policy-making" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Policy making</Link>
                </li>
                <li>
                  <Link to="/research" className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Research</Link>
                </li>
              </Dropdown>
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center ml-4">
            {/* {<img src="https://en.wikipedia.org/w/extensions/UniversalLanguageSelector/resources/images/language-base20.svg?2004a"></img>} */}
              {/* <Dropdown title="🌍">
                <li>
                  <Link to="#" onClick={() => changeLanguage('en')} className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">English</Link>
                </li>
                <li>
                  <Link to="#" onClick={() => changeLanguage('fr')} className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">French</Link>
                </li>
              </Dropdown> */}
              <li>
                <Link to="/contact" className="btn-sm text-gray-100 bg-blue-600 hover:bg-blue-700 ml-3">
                  <span>Get started</span>
                  <svg className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>                  
                </Link>
              </li>
            </ul>

          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">

            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"              
              >
                <ul className="px-5 py-2">
                  <li>
                    {/* 0th level: hover */}
                    <a href='https://maps.open-seneca.org/' className="flex text-gray-600 hover:text-gray-900 py-2">
                      Maps
                    </a>
                  </li>
                  
                  <li className="py-2 my-2 border-t border-gray-200">
                    <span className="flex text-gray-600 hover:text-gray-900 py-2">Technology</span>
                    <ul className="pl-4">
                      <li>
                        <Link to="/air-quality-monitor" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Air quality monitor</Link>
                      </li>
                      {/* <li>
                        <Link to="/data-visualisation" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Data visualisation</Link>
                      </li>                      */}
                    </ul>
                  </li>

                  <li className="py-2 my-2 border-t border-gray-200">
                    <span className="flex text-gray-600 hover:text-gray-900 py-2">Learn</span>
                    <ul className="pl-4">
                    <li>
                      <Link to="/about" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">About us</Link>
                    </li>
                    {/* <li>
                      <Link to="/projects" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Our projects</Link>
                    </li>
                    <li>
                      <Link to="/resources" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Resources</Link>
                    </li>                      */}
                    </ul>
                  </li>

                  <li className="py-2 my-2 border-t border-gray-200">
                    <span className="flex text-gray-600 hover:text-gray-900 py-2">Solutions</span>
                    <ul className="pl-4">
                    <li>
                      <Link to="/community-engagement" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Community Engagement</Link>
                    </li>
                    <li>
                      <Link to="/policy-making" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Policy making</Link>
                    </li>
                    <li>
                      <Link to="/research" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">Research</Link>
                    </li>                    
                    </ul>
                  </li>

                  {/* <li>
                    <Link to="/signin" className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center">{t('signin')}</Link>
                  </li> */}
                  <li>
                    <Link to="/contact" className="btn-sm text-gray-200 bg-blue-600 hover:bg-blue-700 w-full my-2">
                      <span>Get started</span>
                      <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill="#999" fillRule="nonzero" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
