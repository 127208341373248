import React from 'react';

function ResearchFeatures() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">


          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 lg:text-4xl mb-4 text-gray-600 font-red-hat-display font-extrabold">For expanding the frontiers of <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">knowledge</span></h1>
            <p className="text-lg text-gray-600">
              We facilitate researchers who want to tackle the air quality problem heads on.
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-32">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/features-share-image.jpg')} width="100%"  alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">For research</h3>
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">Get access to our air quality datasets for your research.</div>
                  <p className="text-xl text-gray-400 mb-4">We collect datasets of on-the-ground PM2.5 in urban areas with a very high spatial and temporal resolution.</p>
                  <p className="text-xl text-gray-400 mb-4">Help us build knowledge about the short-term and long-term impact of PM2.5 on health, about the spreading of pollution from emission sources, about environmental justice, and many other topics that need addressing.</p>
                  
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default ResearchFeatures;