import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './i18n';
import App from './App';
import ScrollToTop from './utils/ScrollToTop';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback=''>
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
//  basename={'/t/'}
// "homepage": "/t/",
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
