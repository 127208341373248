import React from 'react';

function CommunityEngagementFeatures() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">


          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 className="h1 lg:text-4xl mb-4 text-gray-600 font-red-hat-display font-extrabold">For the <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">community</span>, for <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">you</span></h1>
            <p className="text-lg text-gray-600">
            Engage with air quality in your city and make a difference. There are many different ways you can engage. Find out the ones you are more interested in!
            </p>

          </div>

          {/* Items */}
          <div className="grid gap-32">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/solutions-workshop.jpg')} width="100%"  alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Attend a workshop</h3>
                  <p className="text-xl text-gray-400 mb-4">Learn about air quality monitoring and get the chance to build a sensor. Workshops are suitable for all ages and backgrounds, no technical knowledge needed!</p>
                  <p className="text-xl text-gray-400 mb-4">Check our active projects and find out if we are running workshops in your city or attend one of our webinars online.</p>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/features-build-image.jpg')} width="100%" alt="Features 02" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pr-6" data-aos="fade-left">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3">Measure the air you breathe</h3>
                  <p className="text-xl text-gray-400 mb-4">Get our air quality monitor and take it with you in your daily activities. It is portable, small, and easy to carry.</p>
                  <p className="text-xl text-gray-400 mb-4">You can see the quality of the air you breathe live on the monitor’s screen and use our app to monitor your commutes and see the routes you create.</p>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/features-share-image.jpg')} width="100%"  alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Help us understand hotspots in your city</h3>
                  <p className="text-xl text-gray-400 mb-4">Explore your city air quality map on a street-to-street level. Find where the hotspots are and help us understand why some areas are more polluted than others.</p>
                  <p className="text-xl text-gray-400 mb-4">Use our app to tell us what you think can be done to improve air quality in hotposts and to report your personal experience about exposure in your commutes.</p>
                  {/* <p className="text-xl text-gray-400 mb-4">(Do you want inspiration? Find out what others have discovered in their cities - read our success stories)</p> */}
                </div>
              </div>
            </div>

            {/* 4th item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center bg-gray-100 shadow-lg rounded">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto rounded" src={require('../images/solutions-champion.jpg')} width="100%" alt="Features 02" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pr-6" data-aos="fade-left">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div> */}
                  <h3 className="h3 mb-3">Become a local champion</h3>
                  <p className="text-xl text-gray-400 mb-4">Not yet in your city? Do not wait and become a local champion. Start your own network of sensors, spread the word in your community, and lead the open-seneca movement in your city.</p>
                  <p className="text-xl text-gray-400 mb-4">We run a capacity building programme and will guide you through the process!</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default CommunityEngagementFeatures;